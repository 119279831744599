@import '../../scss/variables/typography.module.scss';
@import '../../scss/helpers/reposinve-breakpoints.module.scss';

.searchBarContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: $blh * 0.5rem;

    @include screen-min(768px) {
        flex-direction: row;
        justify-content: space-between;
    }

    &__button {
        margin-bottom: $blh * 1rem;
    }

    &__inputs {
        @include screen-min(1000px) {
            display: flex;
            flex-direction: row;

            > div:first-of-type {
                margin-right: 20px;
            }
        }
    }
}
