@import '../../../scss/variables/typography.module.scss';
@import '../../../scss/helpers/modular-typography.module';

.t1 {
    @include h3();
    font-weight: 400;
}

.t2 {
    @include h4();
    font-weight: 500;
    padding-bottom: $blh * 0.5rem;
}

.t3 {
    @include h5();
    font-weight: 400;
    padding-bottom: $blh * 0.5rem;
}
