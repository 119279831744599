@import '../../scss/variables/typography.module.scss';
@import '../../scss/helpers/reposinve-breakpoints.module.scss';

$breakpoint: 1000px;

.actionBtnWrapper {
    text-align: right;
    margin-bottom: 1rem * $blh;
}

.hr {
    margin-bottom: $blh * 2rem;
}

.flexContainer {
    @include screen-min($breakpoint) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__item {
        @include screen-min($breakpoint) {
            width: 50%;
        }
    }
}

.section {
    margin-bottom: $blh * 1.5rem;

    @include screen-min($breakpoint) {
        margin-bottom: $blh * 2rem;
    }
}
