@import '../../scss/variables/typography.module.scss';
@import '../../scss/helpers/reposinve-breakpoints.module.scss';

.editContract {
    $breakpoint: 1400px;

    &__fieldsetFlexContainer {
        @include screen-min($breakpoint) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__fieldset {
        margin-bottom: $blh * 2rem;
        max-width: 650px;

        @include screen-min($breakpoint) {
            flex-basis: 45%;
            max-width: 45%;
        }
    }

    &__textareaFieldset {
        margin-bottom: $blh * 1.5rem;
        max-width: 650px;

        @include screen-min($breakpoint) {
            max-width: 100%;
        }
    }
}
