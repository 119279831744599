@import '../../scss/variables/typography.module.scss';

.priceUpload {
  &__form {
    margin-bottom: $blh * 1.5rem;
  }

  &__preview,
  &__alert {
    margin-bottom: $blh * 1rem;
  }
}
