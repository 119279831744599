@import '../../../../scss/variables/typography.module.scss';

.errorHeader {
    margin-bottom: $blh * 0.5rem;
}

.headersList {
    list-style-position: inside;
    margin-bottom: $blh * 0.5rem;
}
