.list {
    $dt-width: 245px;
    display: flex;
    flex-wrap: wrap;

    dt {
        width: $dt-width;
    }

    dd {
        width: calc(100% - #{$dt-width});
        font-weight: 500;
    }
}
