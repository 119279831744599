@import '../../scss/variables/typography.module.scss';
@import '../../scss/helpers/reposinve-breakpoints.module.scss';

.inputsWrapper {
    $breakpoint: 1400px;
    $max-fieldset-width: 500px;

    @include screen-max($breakpoint) {
        max-width: $max-fieldset-width;
    }

    @include screen-min($breakpoint) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    fieldset:not(:last-of-type) {
        margin-bottom: $blh * 1rem;
    }

    fieldset {
        @include screen-min($breakpoint) {
            flex-basis: $max-fieldset-width;
        }
    }
}
