@import '../../../../scss/variables/typography.module.scss';
@import '../../../../scss/helpers/reposinve-breakpoints.module.scss';

$breakpoint: 600px;
$input-width: 250px;

.textInput,
.dateInput,
.numberInput,
.selectInput {
    margin-bottom: $blh * 1rem;

    @include screen-min($breakpoint) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__label {
        display: none;

        @include screen-min($breakpoint) {
            display: block;
        }
    }

    &__inputWrapper {
        @include screen-min($breakpoint) {
            flex: $input-width 0 0;
        }
    }
}

.textareaInput {
    &__inputWrapper {
        margin-bottom: $blh * 0.5rem;
    }
}

.radioInputGroup,
.checkboxGroup {
    margin-bottom: $blh * 1rem;

    @include screen-min($breakpoint) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__inputWrapper {
        @include screen-min($breakpoint) {
            flex: $input-width 0 0;
        }
    }

    .MuiFormGroup {
        flex-direction: row !important;
    }
}
