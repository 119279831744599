@import '../../../../scss/variables/typography.module.scss';
@import '../../../../scss/helpers/reposinve-breakpoints.module.scss';

$breakpoint: 1000px;

.errorWrapper {
    margin-bottom: $blh * 1rem;
}

.commentWrapper {
    margin-bottom: $blh * 1rem;
}

.postBtnWrapper {
    text-align: right;
}
