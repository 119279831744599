@import '../../scss/variables/typography.module.scss';

.saveBtnWrapper {
  text-align: right;
  margin-bottom: $blh * 1rem;
}

.errorWrapper {
  margin-bottom: $blh * 1rem;
}

.formWrapper {
  max-width: 400px;
}