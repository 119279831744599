@import '../../../../scss/variables/typography.module.scss';

.errorsHeader {
    display: flex;
    align-items: center;
    margin-bottom: $blh * 0.5rem;

    svg {
        margin-right: 10px;
    }
}

.errorList {
    padding-left: 35px;

    li {
        margin-bottom: $blh * 0.5rem;
    }
}
