@import '../../scss/helpers/reposinve-breakpoints.module.scss';
@import '../../scss/variables/typography.module.scss';

.flexContainerMeta {
    display: flex;
    justify-content: space-between;
}

.flexContainer {
    $breakpoint: 1240px;

    @include screen-min($breakpoint) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__item {
        @include screen-min($breakpoint) {
            flex-basis: 50%;
        }
    }
}

.container {
    margin-bottom: $blh * 1.5rem;
}

.errorWrapper {
    margin-bottom: $blh * 1rem;
}
