@import '../../../scss/variables/typography.module.scss';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $blh * 0.5rem;
}

.hr {
    margin-bottom: $blh * 0.5rem;
}
