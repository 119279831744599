@import '../../../scss/helpers/modular-typography.module.scss';
@import '../../../scss/variables/typography.module.scss';

.footer {
    @include s1();

    &__hr {
        margin-bottom: $blh * 0.5rem;
    }
}
