@import '../../../scss/variables/typography.module.scss';
@import '../../../scss/helpers/reposinve-breakpoints.module.scss';

.createContract {
    $breakpoint: 1400px;

    &__fieldsetFlexContainer {
        @include screen-min($breakpoint) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__fieldset {
        margin-bottom: $blh * 2rem;
        max-width: 650px;

        @include screen-min($breakpoint) {
            flex-basis: 45%;
            max-width: 45%;
        }
    }
}